import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { ArticleIntro } from '../../types';
import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { LegalInfo } from '../LegalInfo/LegalInfo';

const StyledImageContainer = styled.div`
  padding-bottom: 10px;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  ${(props) => {
    return `
      @media (min-width: ${props.theme.breakpoints.m}px) {
        padding-bottom: 0;
      }
    `;
  }}
`;

const StyledTextContainer = styled.div`
  ${(props) => {
    return `
      padding-top: var(${props.theme.responsive.spacing.m});

      @media (min-width: ${props.theme.breakpoints.m}px) {
        padding-top: 0;
        place-self: center start;
      }
    `;
  }}

  .headline > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 13px !important;
  }

  .copy > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 11px !important;
  }
`;

const StyledBasicTeaserLike = styled.div`
  cursor: pointer;
  pointer-events: all;

  a {
    cursor: pointer;
    pointer-events: all;
  }

  ${(props) => {
    return `
    .teaser-link {
      display: none;
      margin: var(${props.theme.responsive.spacing.xl}) 0;
    }

    @media (min-width: ${props.theme.breakpoints.m}px) {
        display: grid;
        grid-gap: var(${props.theme.responsive.spacing.xxl});
        grid-template-columns: repeat(2, 1fr);

        cursor: auto;
        pointer-events: none;

        .teaser-link {
          display: inline-flex;
          cursor: pointer;
          pointer-events: all;
        }
      }
    `;
  }}
`;

export interface BasicTeaserLikeProps {
  articleIntro: ArticleIntro;
  onTeaserClick(this: void, articleIntro: ArticleIntro): void;
}

export const BasicTeaserLike: React.FC<BasicTeaserLikeProps> = (props) => {
  const { articleIntro, onTeaserClick } = props;

  const title = articleIntro.articleCreationDate || undefined;

  const linkLabel = articleIntro.teaserLinkLabel
    ? articleIntro.teaserLinkLabel
    : useI18n({ id: 'fa.article-teaser.linkCaption' });

  const teaserText = renderTextWithFootnotesReferencesV2(
    enableHTMLEntities(
      articleIntro.teaserTextOverride ? articleIntro.teaserTextOverride : articleIntro.copy,
    ),
  );

  const handleButtonClickEvent = () => {
    onTeaserClick(articleIntro);
  };

  return (
    <StyledBasicTeaserLike>
      {articleIntro.teaserImage && (
        <StyledImageContainer>
          <img
            loading="lazy"
            src={articleIntro.teaserImage}
            alt={articleIntro.teaserImageAlt || ''}
          />
        </StyledImageContainer>
      )}
      <StyledTextContainer>
        {title && (
          <Text variant="copy2" spaceStackEnd="s">
            {title}
          </Text>
        )}
        {articleIntro.headline && (
          <Text className="headline" variant="order3" spaceStackEnd="m">
            {renderTextWithFootnotesReferencesV2(enableHTMLEntities(articleIntro.headline))}
          </Text>
        )}
        {teaserText && (
          <Text className="copy" variant="copy1" spaceStackStart="s" spaceStackEnd="s">
            {teaserText}
          </Text>
        )}
        {articleIntro.teaserLink && (
          <Button
            className="teaser-link"
            href={articleIntro.teaserLink}
            variant="secondary"
            onClick={handleButtonClickEvent}
          >
            {linkLabel}
          </Button>
        )}
        <LegalInfo
          consumptionAndEmissions={
            articleIntro.teaserConsumptionAndEmissionsOverride &&
            articleIntro.teaserConsumptionAndEmissionsOverride.length > 0
              ? articleIntro.teaserConsumptionAndEmissionsOverride
              : articleIntro.consumptionAndEmissions
          }
          disclaimers={
            articleIntro.teaserDisclaimersOverride &&
            articleIntro.teaserDisclaimersOverride.length > 0
              ? articleIntro.teaserDisclaimersOverride
              : articleIntro.disclaimers
          }
        />
      </StyledTextContainer>
    </StyledBasicTeaserLike>
  );
};
