import React from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { ArticleIntro } from '../../types';
import { enableHTMLEntities } from '../../utils/enableHTMLEntities';
import { LegalInfo } from '../LegalInfo/LegalInfo';

const StyledImageContainer = styled.div`
  padding-bottom: 10px;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
`;

const StyledTextContainer = styled.div`
  ${(props) => {
    return `
      padding-top: var(${props.theme.responsive.spacing.m});
    `;
  }}

  .headline > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 13px !important;
  }

  .copy > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 11px !important;
  }
`;

const StyledTileTeaser = styled.div`
  cursor: pointer;
`;

export interface TileTeaserProps {
  articleIntro: ArticleIntro;
  onTeaserClick(this: void, articleIntro: ArticleIntro): void;
}

export const TileTeaser: React.FC<TileTeaserProps> = (props) => {
  const { articleIntro, onTeaserClick } = props;

  const title = articleIntro.articleCreationDate || undefined;

  const teaserText = renderTextWithFootnotesReferencesV2(
    enableHTMLEntities(
      articleIntro.teaserTextOverride ? articleIntro.teaserTextOverride : articleIntro.copy,
    ),
  );

  const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as Element;

    if (target.className !== 'audi-j-footnote-reference') {
      e.preventDefault();
      if (typeof window !== 'undefined') {
        onTeaserClick(articleIntro);
        window.location.href = articleIntro.teaserLink || '';
      }
    }
  };

  return (
    <StyledTileTeaser onClick={handleClickEvent}>
      {articleIntro.teaserImage && (
        <StyledImageContainer>
          <img
            loading="lazy"
            src={articleIntro.teaserImage}
            alt={articleIntro.teaserImageAlt || ''}
          />
        </StyledImageContainer>
      )}
      <StyledTextContainer>
        {title && (
          <Text variant="copy2" spaceStackEnd="s">
            {title}
          </Text>
        )}
        {articleIntro.headline && (
          <Text className="headline" variant="order3" spaceStackEnd="m">
            {renderTextWithFootnotesReferencesV2(enableHTMLEntities(articleIntro.headline))}
          </Text>
        )}
        {teaserText && (
          <Text className="copy" variant="copy1" spaceStackStart="s" spaceStackEnd="s">
            {teaserText}
          </Text>
        )}
        <LegalInfo
          consumptionAndEmissions={
            articleIntro.teaserConsumptionAndEmissionsOverride &&
            articleIntro.teaserConsumptionAndEmissionsOverride.length > 0
              ? articleIntro.teaserConsumptionAndEmissionsOverride
              : articleIntro.consumptionAndEmissions
          }
          disclaimers={
            articleIntro.teaserDisclaimersOverride &&
            articleIntro.teaserDisclaimersOverride.length > 0
              ? articleIntro.teaserDisclaimersOverride
              : articleIntro.disclaimers
          }
        />
      </StyledTextContainer>
    </StyledTileTeaser>
  );
};
