/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';

import { Text, Theme, ThemeProvider, audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';

import { AppContent } from '../../types';
import { useTracking } from '../../utils/useTracking';
import { enableHTMLEntities } from '../../utils/enableHTMLEntities';

import { BasicTeaserLike } from './BasicTeaserLike';
import { TileTeaser } from './TileTeaser';

const StyledArticleTeaser = styled.div`
  .audi-footnote-anchor__text {
    font-size: 0.8em;
    font-weight: lighter;
  }

  .top-headline > .audi-footnote-anchor__text {
    // footnote reference size fix
    vertical-align: super;
    font-size: 24px !important;
  }

  ${({ theme }: { theme: Theme }) => {
    return `
      background-color: var(${theme.colors.background.level[0]});
      padding: var(${theme.responsive.spacing.xxxl}) var(${theme.responsive.spacing.pageMargin});
    `;
  }}
`;

export interface StyledTeaserContainerProps {
  hasMarginTop?: boolean;
  maxColumns: number;
}

const StyledTeaserContainer = styled.div<StyledTeaserContainerProps>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;

  ${(props) => {
    return `
      grid-gap: var(${props.theme.responsive.spacing.l}) var(${props.theme.responsive.spacing.m});

      @media (min-width: ${props.theme.breakpoints.xs}px) {
      }

      @media (min-width: ${props.theme.breakpoints.s}px) {
      }

      @media (min-width: ${props.theme.breakpoints.m}px) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(${Math.min(2, props.maxColumns)}, 1fr);
      }

      @media (min-width: ${props.theme.breakpoints.l}px) {
      }

      @media (min-width: ${props.theme.breakpoints.xl}px) {
        grid-template-columns: repeat(${Math.min(3, props.maxColumns)}, 1fr);
      }

      @media (min-width: ${props.theme.breakpoints.xxl}px) {
        grid-template-columns: repeat(${Math.min(4, props.maxColumns)}, 1fr);
      }
    `;
  }}
`;

export const ArticleTeaser: React.FC<AppContent> = (props) => {
  const { headline, headlineTag, articleIntros, maxColumns, theme } = props;
  const singleTeaserMode = Boolean(articleIntros && articleIntros.length === 1);

  const ref = React.useRef(null);
  const tracking = useTracking(
    ref,
    headline,
    articleIntros && articleIntros.length > 0
      ? articleIntros.map((articleIntro) => ({
          elementName: 'article',
          elementValue: `${articleIntro.index + 1}-${articleIntro.headline}`,
        }))
      : [],
    {
      implementer: 37,
    },
  );

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  return (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <StyledArticleTeaser ref={ref} data-test-id="article-teaser">
        {headline && (
          <Text className="top-headline" as={headlineTag} variant="order1" spaceStackEnd="xl">
            <UeElement type="text" property="headline" label="Headline">
              {renderTextWithFootnotesReferencesV2(enableHTMLEntities(headline))}
            </UeElement>
          </Text>
        )}
        {!singleTeaserMode && (
          <StyledTeaserContainer maxColumns={maxColumns}>
            {articleIntros &&
              articleIntros.map((articleIntro, i) => (
                <TileTeaser
                  key={`item-${i}`}
                  articleIntro={articleIntro}
                  onTeaserClick={tracking.sendClickEvent}
                />
              ))}
          </StyledTeaserContainer>
        )}
        {singleTeaserMode && (
          <BasicTeaserLike
            articleIntro={articleIntros![0]}
            onTeaserClick={tracking.sendClickEvent}
          />
        )}
      </StyledArticleTeaser>
    </ThemeProvider>
  );
};
